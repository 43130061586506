import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { ProjectsSortCol, TProjectList, TProjectListResponse, TProjectQuery, TProjectsQueryRequest, TProjectsSortCol, adaptProjectFromListResponse } from '../../types';
import { BACKEND_PROJECTS } from '../../backend-paths';


export const projectsGetApi = createAsyncThunk(
	BACKEND_PROJECTS,
	(payload: TProjectQuery | undefined, thunkApi) => {
		const params = payload ? toProjectsQueryRequest(payload) : {};

		return HttpService.get<TProjectListResponse>(BACKEND_PROJECTS, { params }).then(result => {
			return adaptProjectFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toProjectsQueryRequest(data: TProjectQuery): TProjectsQueryRequest {
	const {
		isArchived,
		sortCol,
		...restFields
	} = data;

	return {
		is_archived: isArchived,
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	isLoading: boolean;
	list: TProjectList;
	query: TProjectQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: ProjectsSortCol.CREATED_AT,
	},
	error: undefined,
};

export const projectsSlice = createSlice({
	name: 'projectsSlice',
	initialState,
	reducers: {
		setProjectsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setProjectsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setProjectsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setProjectsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setProjectsQuerySortCol(state, action: PayloadAction<TProjectsSortCol>) {
			state.query.sortCol = action.payload;
		},

		setProjectsQueryIsArchived(state, action: PayloadAction<boolean>) {
			state.query.isArchived = action.payload;
		},

		clearProjects(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[projectsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[projectsGetApi.fulfilled.type]: (state, action: PayloadAction<TProjectList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[projectsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useProjectsSelector = () => useAppSelector(state => state[projectsSlice.name]);

export const { clearProjects, setProjectsQueryLimit, setProjectsQueryOffset, setProjectsQuerySort, setProjectsQuerySortCol, setProjectsQueryIsArchived, setProjectsQuerySearch } = projectsSlice.actions;
