import { libClearIntstr, libFormatDate } from 'src/toolkit';


export type TAccountInternationalPassportFile = {
	name: string;
	path: string;
};

export type TAccountInternationalPassportFileRequest = Array<File | string>;

export type TAccountInternationalPassport = {
	name: string;
  passportNumber: string;
  expiredAt: string;
  issuedBy: string;
  files: TAccountInternationalPassportFile[];
	createdAt: string;
	updatedAt: string;
};

export type TAccountInternationalPassportBodyRequest = {
	name?: string;
  passportNumber?: string;
  expiredAt?: string;
  issuedBy?: string;
};

export type TAccountInternationalPassportResponse = {
	name: string;
	passport_number: string;
  expired_at: string;
  issued_by: string;
  files: TAccountInternationalPassportFile[];
	created_at: string;
	updated_at: string;
};

export type TAccountInternationalPassportRequest = Omit<TAccountInternationalPassportResponse, 'files' | 'created_at' | 'updated_at'>;

export type TAccountInternationalPassportPayload = {
	data: TAccountInternationalPassportBodyRequest;
	files: TAccountInternationalPassportFileRequest;
};

export function adaptAccountInternationalPassportFromResponse(data: TAccountInternationalPassportResponse): TAccountInternationalPassport {
	const {
		name = '',
		passport_number: passportNumber = '',
		expired_at: expiredAtRaw,
		issued_by: issuedBy = '',
		files = [],
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const expiredAt = expiredAtRaw ? libFormatDate(expiredAtRaw, { template: 'yyyy-MM-dd' }) : '';

	return {
		name,
		passportNumber,
		expiredAt,
		issuedBy,
		files,
		createdAt,
		updatedAt,
	};
}

export function adaptAccountInternationalPassportToRequest(data: TAccountInternationalPassportBodyRequest): TAccountInternationalPassportRequest {
	const {
		name = '',
		passportNumber = '',
		expiredAt = '',
		issuedBy = '',
	} = data;

	return {
		name: name,
		passport_number: libClearIntstr(passportNumber),
		expired_at: expiredAt,
		issued_by: issuedBy,
	};
}

