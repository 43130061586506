import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUser, TUserResponse, adaptUserFromResponse } from './user.type';
import { TProjectClient, TProjectClientResponse, adaptProjectClientFromResponse } from './project-client.type';
import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TWorkArea, TWorkAreaResponse, adaptWorkAreaFromResponse } from './work-area.type';


export const ProjectsSortCol = {
	NAME: 'name',
	WORK_AREA: 'work_area',
	TRADE_ID: 'trade_id',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsSortCol = TTableSortCols<typeof ProjectsSortCol>;

export type TProjectQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsSortCol;
	isArchived?: boolean;
};

export type TProjectsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
	is_archived?: boolean;
};

export type TProject = {
  id: number;
  name: string;
  shortDescription?: string;
  description?: string;
  tradeId?: string;
  isArchived?: boolean;
	charged?: TUser;
	client?: TProjectClient;
	workArea?: TWorkArea;
	createdAt: string;
  updatedAt: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectResponse = {
  id: number;
  name: string;
  short_description?: string;
  description?: string;
	trade_id?: string;
	is_archived?: boolean;
	charged: TUserResponse;
	client: TProjectClientResponse;
	work_area: TWorkAreaResponse;
  created_at: string;
  updated_at: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProjectListResponse = TListResponse<TProjectResponse>;

export type TProjectList = TListResponse<TProject>;

export type TProjectBodyRequest = {
	name: string;
	shortDescription?: string;
	description?: string;
	tradeId?: string;
	isArchived?: boolean;
	chargedNickname?: string;
	clientId?: number;
	workAreaId?: number;
};

export type TProjectRequest = {
	name: string;
	short_description?: string;
	description?: string;
	trade_id?: string;
	is_archived?: boolean;
	charged_nickname?: string;
	client_id?: number;
	work_area_id?: number;
};

export type TProjectUpdate = {
	id: number;
	data: TProjectBodyRequest;
};

export function adaptProjectFromListResponse(data: TProjectListResponse): TProjectList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectFromResponse(data: TProjectResponse): TProject {
	const {
		id,
		name,
		short_description: shortDescription,
		description,
		trade_id: tradeId,
		is_archived: isArchived,
		charged: chargedRaw,
		client: clientRaw,
		work_area: workAreaRaw,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const charged = chargedRaw ? adaptUserFromResponse(chargedRaw) : undefined;
	const client = clientRaw ? adaptProjectClientFromResponse(clientRaw) : undefined;
	const workArea = workAreaRaw ? adaptWorkAreaFromResponse(workAreaRaw) : undefined;

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		name,
		shortDescription,
		description,
		tradeId,
		isArchived,
		charged,
		client,
		workArea,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptProjectToRequest(data: TProjectBodyRequest): TProjectRequest {
	const {
		name,
		shortDescription = undefined,
		description = undefined,
		tradeId = undefined,
		isArchived = undefined,
		chargedNickname = undefined,
		clientId = undefined,
		workAreaId = undefined,
	} = data;

	return {
		name,
		short_description: shortDescription,
		description,
		trade_id: tradeId,
		is_archived: isArchived,
		charged_nickname: chargedNickname,
		client_id: clientId,
		work_area_id: workAreaId,
	};
}
