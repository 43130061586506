import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';

import { TUserMeta, TUserMetaResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TProject, TProjectResponse, adaptProjectFromResponse } from './project.type';


export const ProjectsOrdersSortCol = {
	ID: 'id',
	NAME: 'name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TProjectsOrdersSortCol = TTableSortCols<typeof ProjectsOrdersSortCol>;

export type TProjectOrderQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TProjectsOrdersSortCol;
};

export type TProjectsOrdersQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TProjectOrderPayloadQuery = {
	projectId: number;
	data?: TProjectOrderQuery;
};

export function adaptProjectsOrdersQueryRequest(data: TProjectOrderQuery): TProjectsOrdersQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TProjectOrder = {
  id: number;
  projectId: number;
  name: string;
  description?: string;
  paymentTypeId?: number;
  maxOverwork?: number;
  reportDelay?: number;
  isReportDelayByLast?: boolean;
	project: TProject;
	createdAt?: string;
  updatedAt?: string;

	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TProjectOrderResponse = {
  id: number;
  project_id: number;
  name: string;
  description?: string;
	payment_type_id?: number;
	max_overwork?: number;
	report_delay?: number;
	is_report_delay_by_last?: boolean;
	project: TProjectResponse;
  created_at?: string;
  updated_at?: string;

	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TProjectOrderListResponse = TListResponse<TProjectOrderResponse>;

export type TProjectOrderList = TListResponse<TProjectOrder>;

export type TProjectOrderBodyRequest = {
	name: string;
	description?: string;
	paymentTypeId?: number;
	maxOverwork?: number;
	reportDelay?: number;
	isReportDelayByLast?: boolean;
};

export type TProjectOrderRequest = {
	name: string;
	description?: string;
	payment_type_id?: number;
	max_overwork?: number;
	report_delay?: number;
	is_report_delay_by_last?: boolean;
};

export type TProjectOrderPayload = {
	id: number;
	projectId: number;
};

export type TProjectOrderPayloadCreate = {
	projectId: number;
	data: TProjectOrderBodyRequest;
};

export type TProjectOrderPayloadUpdate = TProjectOrderPayloadCreate & {
	id: number;
};

export function adaptProjectOrderFromListResponse(data: TProjectOrderListResponse): TProjectOrderList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptProjectOrderFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptProjectOrderFromResponse(data: TProjectOrderResponse): TProjectOrder {
	const {
		id,
		project_id: projectId,
		name,
		description,
		payment_type_id: paymentTypeId,
		max_overwork: maxOverwork,
		report_delay: reportDelay,
		is_report_delay_by_last: isReportDelayByLast,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
		project: projectRaw,
	} = data;

	const project = adaptProjectFromResponse(projectRaw);

	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		projectId,
		name,
		description,
		paymentTypeId,
		maxOverwork,
		reportDelay,
		isReportDelayByLast,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
		project,
	};
}

export function adaptProjectOrderToRequest(data: TProjectOrderBodyRequest): TProjectOrderRequest {
	const {
		name = '',
		description = undefined,
		paymentTypeId = undefined,
		maxOverwork = undefined,
		reportDelay = undefined,
		isReportDelayByLast = undefined,
	} = data;

	return {
		name,
		description,
		payment_type_id: paymentTypeId,
		max_overwork: maxOverwork,
		report_delay: reportDelay,
		is_report_delay_by_last: isReportDelayByLast,
	};
}
