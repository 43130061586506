import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TExpenseTypeSourceList, TExpenseTypeSourceListResponse, TExpenseTypeSourceQuery, adaptExpenseTypeSourceFromListResponse, adaptEquipmentsQueryToRequest } from '../../types';
import { BACKEND_SOURCE_EXPENSES_TYPES } from '../../backend-paths';


export const sourceExpensesTypesGetApi = createAsyncThunk(
	BACKEND_SOURCE_EXPENSES_TYPES,
	(payload: TExpenseTypeSourceQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsQueryToRequest(payload);

		return HttpService.get<TExpenseTypeSourceListResponse>(BACKEND_SOURCE_EXPENSES_TYPES, { params }).then(result => {
			return adaptExpenseTypeSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TExpenseTypeSourceList;
	query: TExpenseTypeSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceExpensesTypesSlice = createSlice({
	name: 'sourceExpensesTypesSlice',
	initialState,
	reducers: {
		setSourceExpensesTypesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceExpensesTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceExpensesTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceExpensesTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TExpenseTypeSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceExpensesTypesGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceExpensesTypesSelector = () => useAppSelector(state => state[sourceExpensesTypesSlice.name]);

export const { clearSourceExpensesTypes, setSourceExpensesTypesQuerySearch } = sourceExpensesTypesSlice.actions;
