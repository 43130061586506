import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { TUserMeta, TUserMetaResponse, adaptUserMetaFromResponse, adaptUserMetaUndefinedFromResponse } from './user-meta.type';
import { TScheduleEventType, TScheduleEventTypeResponse, adaptScheduleEventTypeFromResponse } from './schedule-event-type.type';


export const ScheduleEventsSortCol = {
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TScheduleEventsSortCol = TTableSortCols<typeof ScheduleEventsSortCol>;

export type TScheduleEventQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TScheduleEventsSortCol;
};

export type TScheduleEventsQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export function adaptScheduleEventsQueryToRequest(data?: TScheduleEventQuery): TScheduleEventsQueryRequest {
	if (!data) {
		return {};
	}

	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

export type TScheduleEvent = {
  id: number;
  typeId: number;
	userNickname: string;
	dateStart: string;
	dateEnd: string;
	createdAt: string;
  updatedAt: string;

	type: TScheduleEventType;
	user: TUserMeta;
	createdBy?: TUserMeta;
	updatedBy?: TUserMeta;
};

export type TScheduleEventResponse = {
  id: number;
  type_id: number;
	user_nickname: string;
	date_start: string;
	date_end: string;
  created_at: string;
  updated_at: string;

	type: TScheduleEventTypeResponse;
	user: TUserMetaResponse;
	created_by: TUserMetaResponse;
	updated_by: TUserMetaResponse;
};

export type TScheduleEventListResponse = TListResponse<TScheduleEventResponse>;

export type TScheduleEventList = TListResponse<TScheduleEvent>;

export type TScheduleEventBodyRequest = {
  typeId: number;
	userNickname: string;
	dateStart: string;
	dateEnd?: string;
};

export type TScheduleEventRequest = {
  type_id: number;
	user_nickname: string;
	date_start: string;
	date_end?: string;
};

export type TScheduleEventUpdate = {
	id: number;
	data: TScheduleEventBodyRequest;
};

export function adaptScheduleEventFromListResponse(data: TScheduleEventListResponse): TScheduleEventList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptScheduleEventFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptScheduleEventFromResponse(data: TScheduleEventResponse): TScheduleEvent {
	const {
		id,
		type_id: typeId,
		user_nickname: userNickname,
		date_start: dateStart,
		date_end: dateEnd,
		type: typeRaw,
		user: userRaw,
		created_by: createdByRaw,
		updated_by: updatedByRaw,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	const user = adaptUserMetaFromResponse(userRaw);
	const type = adaptScheduleEventTypeFromResponse(typeRaw);
	const createdBy = adaptUserMetaUndefinedFromResponse(createdByRaw);
	const updatedBy = adaptUserMetaUndefinedFromResponse(updatedByRaw);

	return {
		id,
		typeId,
		userNickname,
		dateStart,
		dateEnd,
		type,
		user,
		createdBy,
		updatedBy,
		createdAt,
		updatedAt,
	};
}

export function adaptScheduleEventToRequest(data: TScheduleEventBodyRequest): TScheduleEventRequest {
	const {
		typeId,
		userNickname,
		dateStart,
		dateEnd,
	} = data;

	return {
		type_id: typeId,
		user_nickname: userNickname,
		date_start: dateStart,
		date_end: dateEnd,
	};
}
