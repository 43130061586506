import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TScheduleEvent, TScheduleEventBodyRequest, TScheduleEventResponse, adaptScheduleEventFromResponse, adaptScheduleEventToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SCHEDULE_EVENTS } from '../../backend-paths';


export const scheduleEventCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_SCHEDULE_EVENTS}`,
	(payload: TScheduleEventBodyRequest, thunkApi) => {
		const dataRequest = adaptScheduleEventToRequest(payload);

		return HttpService.post<TScheduleEventResponse>(BACKEND_ADMIN_SCHEDULE_EVENTS, dataRequest).then(result => {
			return adaptScheduleEventFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEvent> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const scheduleEventCreateSlice = createSlice({
	name: 'scheduleEventCreateSlice',
	initialState,
	reducers: {
		clearScheduleEventCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[scheduleEventCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[scheduleEventCreateApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEvent>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[scheduleEventCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useScheduleEventCreateSelector = () => useAppSelector(state => state[scheduleEventCreateSlice.name]);

export const { clearScheduleEventCreate } = scheduleEventCreateSlice.actions;
