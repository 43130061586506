import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TEquipmentSourceList, TEquipmentSourceListResponse, TEquipmentSourceQuery, adaptEquipmentSourceFromListResponse, adaptEquipmentsQueryToRequest } from '../../types';
import { BACKEND_SOURCE_EQUIPMENTS } from '../../backend-paths';


export const sourceEquipmentGetApi = createAsyncThunk(
	BACKEND_SOURCE_EQUIPMENTS,
	(payload: TEquipmentSourceQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsQueryToRequest(payload);

		return HttpService.get<TEquipmentSourceListResponse>(BACKEND_SOURCE_EQUIPMENTS, { params }).then(result => {
			return adaptEquipmentSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentSourceList;
	query: TEquipmentSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceEquipmentSlice = createSlice({
	name: 'sourceEquipmentSlice',
	initialState,
	reducers: {
		setSourceEquipmentQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceEquipment(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceEquipmentGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceEquipmentGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceEquipmentGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceEquipmentSelector = () => useAppSelector(state => state[sourceEquipmentSlice.name]);

export const { clearSourceEquipment, setSourceEquipmentQuerySearch } = sourceEquipmentSlice.actions;
