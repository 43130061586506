import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TEquipmentGroupSourceList, TEquipmentGroupSourceListResponse, TEquipmentGroupSourceQuery, adaptEquipmentGroupSourceFromListResponse, adaptEquipmentsQueryToRequest } from '../../types';
import { BACKEND_SOURCE_EQUIPMENTS_GROUPS } from '../../backend-paths';


export const sourceEquipmentsGroupsGetApi = createAsyncThunk(
	BACKEND_SOURCE_EQUIPMENTS_GROUPS,
	(payload: TEquipmentGroupSourceQuery | undefined, thunkApi) => {
		const params = adaptEquipmentsQueryToRequest(payload);

		return HttpService.get<TEquipmentGroupSourceListResponse>(BACKEND_SOURCE_EQUIPMENTS_GROUPS, { params }).then(result => {
			return adaptEquipmentGroupSourceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean;
	list: TEquipmentGroupSourceList;
	query: TEquipmentGroupSourceQuery;
	error?: HttpError;
};

const initialState: TInitialState = {
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 50,
		offset: 0,
		search: undefined,
	},
	error: undefined,
};

export const sourceEquipmentsGroupsSlice = createSlice({
	name: 'sourceEquipmentsGroupsSlice',
	initialState,
	reducers: {
		setSourceEquipmentsGroupsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		clearSourceEquipmentsGroups(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceEquipmentsGroupsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceEquipmentsGroupsGetApi.fulfilled.type]: (state, action: PayloadAction<TEquipmentGroupSourceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceEquipmentsGroupsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSourceEquipmentsGroupsSelector = () => useAppSelector(state => state[sourceEquipmentsGroupsSlice.name]);

export const { clearSourceEquipmentsGroups, setSourceEquipmentsGroupsQuerySearch } = sourceEquipmentsGroupsSlice.actions;
