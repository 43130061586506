import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TScheduleEvent, TScheduleEventUpdate, TScheduleEventResponse, adaptScheduleEventFromResponse, adaptScheduleEventToRequest } from '../../types';
import { TSliceInitialState, useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_SCHEDULE_EVENTS } from '../../backend-paths';


export const scheduleEventUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_SCHEDULE_EVENTS}/:id`,
	(payload: TScheduleEventUpdate, thunkApi) => {
		const { data, id } = payload;
		const path = `${BACKEND_ADMIN_SCHEDULE_EVENTS}/${id}`;
		const dataRequest = adaptScheduleEventToRequest(data);

		return HttpService.put<TScheduleEventResponse>(path, dataRequest).then(result => {
			return adaptScheduleEventFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

const initialState: TSliceInitialState<TScheduleEvent> = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const scheduleEventUpdateSlice = createSlice({
	name: 'scheduleEventUpdateSlice',
	initialState,
	reducers: {
		clearScheduleEventUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[scheduleEventUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[scheduleEventUpdateApi.fulfilled.type]: (state, action: PayloadAction<TScheduleEvent>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[scheduleEventUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useScheduleEventUpdateSelector = () => useAppSelector(state => state[scheduleEventUpdateSlice.name]);

export const { clearScheduleEventUpdate } = scheduleEventUpdateSlice.actions;
