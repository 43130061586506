import { libFormatDate } from 'src/toolkit';


export type TUserWorkDataFile = {
	name: string;
	path: string;
};

export type TUserWorkDataFileRequest = Array<File | string>;

export type TUserWorkData = {
	domainLogin: string;
  stateDate: string;
  createdAt: string;
  updatedAt: string;

  files: TUserWorkDataFile[];
};

export type TUserWorkDataBodyRequest = {
	domainLogin?: string;
  stateDate?: string;
};

export type TUserWorkDataResponse = {
	domain_login: string;
  state_date: string;
  created_at: string;
  updated_at: string;

  files: TUserWorkDataFile[];
};

export type TUserWorkDataRequest = {
	domain_login?: string;
  state_date?: string;
};

export type TUserWorkDataPayload = {
	nickname: string;
	data: TUserWorkDataBodyRequest;
	files: TUserWorkDataFileRequest;
};

export function adaptUserWorkDataFromResponse(data: TUserWorkDataResponse): TUserWorkData {
	const {
		domain_login: domainLogin = '',
		state_date: stateDateRaw,
		updated_at: createdAt,
		created_at: updatedAt,

		files = [],
	} = data;

	const stateDate = stateDateRaw ? libFormatDate(stateDateRaw, { template: 'yyyy-MM-dd' }) : '';

	return {
		domainLogin,
		stateDate,
		createdAt,
		updatedAt,

		files,
	};
}

export function adaptUserWorkDataToRequest(data: TUserWorkDataBodyRequest): TUserWorkDataRequest {
	const {
		domainLogin,
		stateDate,
	} = data;

	return {
		domain_login: domainLogin,
		state_date: stateDate,
	};
}

