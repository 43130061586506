import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { FormField, FormInput } from 'src/components';
import { TInputField } from 'src/toolkit';

import styles from './FormInputField.module.scss';


type TFormInputField = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & TInputField & {
	legend?: string;
	placeholder?: string;
	title?: string;

	readonly?: boolean;
	isLoading?: boolean;

	className?: string;

	// autoComplete?: boolean;

	marginLeft?: boolean;
	marginRight?: boolean;
	nowrap?: boolean;
	width100?: boolean;
};

export function FormInputField(props: TFormInputField) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,

		type = 'text',
		readonly = false,
		// autoComplete: autoCompleteRaw = false,

		legend = '',
		placeholder = '',
		title = '',

		className: classNameOuter = '',

		marginLeft,
		marginRight,
		nowrap,
		width100,
	} = props;

	const classNames = [];

	if (classNameOuter) {
		classNames.push(classNameOuter);
	}

	if (marginLeft) {
		classNames.push(styles.marginLeft);
	}

	if (marginRight) {
		classNames.push(styles.marginRight);
	}

	if (nowrap) {
		classNames.push(styles.nowrap);
	}

	if (width100) {
		classNames.push(styles.width100);
	}

	// const autoComplete = autoCompleteRaw ? 'on' : 'off';

	return (
		<FormField
			className={ classNames.join(' ') }
			legend={ legend }
			errors={ errors }
			required
		>
			<FormInput
				type={ type }
				placeholder={ placeholder }
				name={ name }
				title={ title }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
				readOnly={ readonly }
				// autoComplete={ autoComplete || undefined }
			/>
		</FormField>
	);
}
